.nav-body{
    display: flex;
    flex-direction: row5;
    position: fixed;
    background-color: orange;
    height: 200px;
    width: 75px;
    border-radius: 10px;
    transition: width 3s;
    top: 30%;
    left: -10px;
    overflow: hidden;
    z-index: 1;
}

.nav-body:hover{
    width: 300px;
}

.nav-title{
    position: relative;
    margin-left: 1%;
    left: 12.5px;
    margin-top: auto;
    margin-bottom: auto;
    color: white;
    font-weight: 700;
    letter-spacing: 2px;
}


.options{
    position: relative;
    align-items: center;
    margin-left: 25%;
    margin-top: auto;
    margin-bottom: auto;
    color: white;
    text-decoration: underline;
    letter-spacing: 2px;
    
}

.nav-items{
    padding:20px;
    color: white;
    font-weight: 700;
}
