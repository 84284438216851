.projects-body-flex{
    display: flex;
    flex-direction: column;
}

.project-title-container{
    margin: auto;
    text-align: center;

}

.title-text{
    font-size: 30px;
    text-align: center;
    color: white;
}



.container{
    height: 1000px;
}