.about-title {
    color: orange;
    margin: auto;
    align-items: center;
    text-align: center;
    position: relative;
    top: 200px;
    font-size: 30px;
}



.aboutContainer{
display: flex;
height: 300%;
margin: auto;
position: relative;
top: 110%;
}

.about-item-1{
    position: relative;
    bottom: 100px;
    width: 80%;
    display: inline-block;
    margin-top: 20%;
    margin-left: 20%;
    margin-right: auto;
    text-align: center;
}


.image-container{
    position: relative;
    float: left;
    margin-left: 7.5%;
    width:50%;
    height: 100%;
    overflow: hidden;
}

.about-image-1{
    position: relative;
    float: left;
    max-height: 500px;
    max-width: 600px;
    overflow: hidden;
    z-index: 1;
}

.text-container{
    position: absolute;
    background-color: rgba(51, 51, 51, 0.9);
    left: 30%;
    top: 50%;
    z-index: 9;
    width: 30%;
    padding: 1%;
    border-right: white solid 2px;
    height: 70%;
    overflow-y: scroll;
}


.exit{
    position: absolute;
    left: 5%;
    color: white;
    text-align: left;
    font-size: 30px;
    top: 20%;
    display: block;
}

.exit:hover{
    cursor: pointer;
}

em, h2 {
    color: orange;
    font-weight: 600;
}

.h1 {
    font-weight: 700;
    font-size: 20px;
}

.hobbie-header-container{
    margin: auto;
    position: absolute;
    top: 1050px;
    color: rgba(232, 126, 4, 0.3);
    font-size: 100px;
    width: 100%;
}



.about-item-2 {

    display: flex;
    flex-direction: row;
    position: absolute;
    top: 150%;
    align-items: start;
    text-align: center;
    width: 100%;
    margin:auto;
    justify-content: center;
}


.hobbie-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 33.333%;
}

.hobbie-title{
    color: white;
    font-size: 15px;
    letter-spacing: 3px;
}

.hobbie-body{
color: white;
width: 90%;
margin: auto;
font-family: Arial, Helvetica, sans-serif;
}

.hobbie{    
    
    border-radius: 100%;
    height: 100px;
    width: 100px;
    position: relative;
    top: 100%;
    margin: 100px;
    border: 1px solid orange;
}

.hobbie:hover{
    cursor: pointer;
}

.flex-container{
    display: flex;
    flex-direction: row;
}



/* ------- MOBILE RESPONSIVENESS  ------*/ 

@media (max-width: 450px){

    #Body_1{
        margin-top: 170%;
    }

    #Image_Container{
        width: 100%;
        margin-left: 0;
    }

    #About_Item_1{
        margin-left: 5px;
        top: 20px;
    }

    #About_Image_1 {
        max-height: 200px;
    }

    #Text_Container {
        width: 100%;
        left: 20px;
        height: 100%;
        top: 70%;
        overflow-x: scroll;
    }

    #Exit{
        left: 85%;
    }

    #About-Item-2{
        position: absolute;
        top: 750px;
        flex-direction: column;
        align-items: center;
    }

    #Hobbie-Header-Container{
        font-size: 30px;
        top: 500px;
        color: rgba(232, 126, 4, 0.8);


    }


  #Gym-Cont, #Books-Cont, #PS-Cont{
      width: 100%;
      border-bottom: white 2px solid;
      
    }
    
    #Hobbie_Title{
        color: orange;
      
       
    }
    
    .hobbie-body{
    color: white;
    width: 90%;
    margin: auto;
    font-family: Arial, Helvetica, sans-serif;
    }
    
    #Gym, #Reading, #PS{    
        
        border-radius: 100%;
        height: 100px;
        width: 100px;
        position: relative;
        top: 100%;
        margin: 20px;
        border: 1px solid orange;
    }

    #About-Title{
        top: 0px;
    }


}