html{
  height: 100%;
}

body{
background-color:rgb(36, 30, 32);
min-height: 100%;
width: auto;
}

.background {
  background-image: url(../src/media/backgroundimageV2.jpg);
}

.App {
  text-align: center;


}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

p {
  color: white;
}

