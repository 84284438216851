
body{
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
}


.bg {
  position: absolute;
  /* The image used */
  background-image: url("../media/stock-images/close-up-bulb.jpg");

  /* Full height */
  height: 100%; 
  width: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}



.welcomeTxt{
  background-color:rgba(36, 30, 32, 0.9);
  color: white;
  font-size: 60px;
  width: 50%;
  margin: auto;
  margin-top: 15%;
  font-family: Arial, Helvetica, sans-serif;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
  font-weight: bolder;
  /*box-shadow: 10px 10px white;*/
  text-align: center;
  padding:5px;
  height: 100%;
}


/********************** ARROW POINTER */
.arrow{
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.arrow span{
    display: block;
    width: 30px;
    height: 30px;
    border-bottom: 5px solid white;
    border-right: 5px solid white;
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;
}
.arrow span:nth-child(2){
    animation-delay: -0.2s;
}
.arrow span:nth-child(3){
    animation-delay: -0.4s;
}
@keyframes animate {
    0%{
        opacity: 0;
        transform: rotate(45deg) translate(-20px,-20px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(45deg) translate(20px,20px);
    }
}


.body1{
height: 100%;
margin-top: 40%;
}

.fadeTest {
  font-size: 50px;
  color: antiquewhite;
}


.content1{
  display: flex;
  height: 100px;
  width: 100%;
  position: relative;
  top: 15%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.bodyText{

  background-color:rgba(36, 30, 32, 0.9);
  color: white;
  font-size: 60px;
  width: 50%;
  margin: auto;
  margin-top: 15%;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;

}

a {
  text-decoration: none;
  color: white;
}

a:visited {
  text-decoration:none;
  color: white;
}


.bodyText:hover {
  cursor: pointer;
}







/***** Mobile Responsiveness *****/

@media(max-width: 768px){

  .welcomeTxt{
    font-size: 60px;
    opacity: 0.8;
    width: 50%;
    margin: auto;
    font-weight: bolder;
    text-align: center;
    padding: 5px;
     }
  }
  
  @media(max-width: 400px){
    
    #welcomeTxt{
  
      
      font-size: 40px;
      opacity: 0.8;
      width: 50%;
      margin: auto;
      font-weight: bolder;
      text-align: center;
      padding: 5px;
      margin-top: 55%;
    }



    .content1{

      flex-direction: column;

    }

    .body1{
      margin-top: 140%;
    }
    
  }


